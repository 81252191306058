import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import LogoImg from "../images/Huella-fondo-transparente.png"

// styles
const pageStyles = {
    color: "#232129",
    padding: "96px",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
}

const paragraphStyles = {
    marginBottom: 48,
}

// markup
const IndexPage = () => {
    return (
        <main style={pageStyles}>
            <title>Not found</title>

            <Container fluid="md">
                <Row>
                    <Col>
                        <img src={LogoImg} nosend="1" border="0" style={{ maxWidth: "100%" }} alt="Aurora Carmona Hidalgo y Bruma en la clínica" />
                    </Col>
                    <Col>
                        <h1 style={headingStyles}>Page not found (404)</h1>
                        <p style={paragraphStyles}>
                            Sorry, we couldn’t find what you were looking for.
                            <br/>
                            <Link to="/">Clínica veterinaria la Huella Verde</Link>
                            <br/>
                        </p>
                    </Col>
                </Row>
            </Container>
        </main>
    )
}

export default IndexPage
